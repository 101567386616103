import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation(); // Hook to access the current location

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever the location changes
  }, [location]); // React to changes in the location

  return null; // This component does not render any visual elements
};

export default ScrollToTop;
