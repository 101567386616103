import { combineReducers } from "redux";

// //import { userReducer } from './user/user.reducer';
// import { postReducer } from './post/post.reducer';
// //import { gameReducer } from './game/game.reducer';
// import { notificationReducer } from './notification/notification.reducer';
// //import { oddTypeReducer } from './oddType/oddType.reducer';
// import { resultReducer } from './result/result.reducer';
// import  betslipReducer  from './_front/betslipSlice';
// import { contestReducer } from './contestSlip/contestslip.reducer';
// //import { pageReducer } from './page/page.reducer';
// import { preGameReducer } from './pregame/pregame.reducer';
//toolkit
import betslipReducer from "./_front/betslipSlice";
import oddTypeReducer from "./_front/oddTypeSlice";
import pageReducer from "./_front/pageSlice";
import gamesReducer from "./_front/sportsDataSlice";
//import prematchReducer from './_prematch/prematchSlice';
import sportsDataReducer from "./_front/sportsDataSlice";
import userReducer from "./_front/userSlice";
import themeReducer from "./_front/themeSlice";
import rmgtReducer from "./_common/rmgtSlice";
//for admin
import adminUserReducer from "./_admin/user/adminUserSlice";
import mybetsReducer from "./_front/mybetsSlice";
import sliderReducer from "./_front/sliderSlice";

export const rootReducer = combineReducers({
  //for others
  user: userReducer,
  theme: themeReducer,
  //post: postReducer,
  sportsData: sportsDataReducer,
  //prematch: prematchReducer,
  //game: gamesReducer,
  //pregame: preGameReducer,
  //notification: notificationReducer,
  oddType: oddTypeReducer,
  //results: resultReducer,
  betslip: betslipReducer,
  mybets: mybetsReducer,
  //contestslip:contestReducer,
  page: pageReducer,
  rmgt: rmgtReducer,
  slider: sliderReducer,

  //for admin
  adminUser: adminUserReducer,
});
