import { useEffect, useState, Suspense, lazy, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "./redux/store";

import { handleUpdateSlider } from "./redux/_front/sliderSlice";
import { updateUserDetails } from "./redux/_front/userSlice";
import { setRmgt } from "./redux/_common/rmgtSlice";
import { fetchRmgt } from "./api/_common/rmgtApi";
//for loader
import Loader from "./components/loader/Loader";

//import { useSocket, isSocketConnected, socket } from './socket/socket';

//for admins
const AdminDashboard = lazy(() => import("./pages/admin/Dashboard.page"));
const AdminUsers = lazy(() => import("./pages/admin/Users.page"));
const AdminEvents = lazy(() => import("./pages/admin/Events.page"));
const AdminExchangeEvents = lazy(() =>
  import("./pages/admin/ExchangeEvents.page")
);
const AdminEventDetails = lazy(() => import("./pages/admin/EventDetails.page"));
const AdminExchangeEventDetails = lazy(() =>
  import("./pages/admin/ExchangeEventDetails.page")
);
const AdminTickets = lazy(() => import("./pages/admin/Tickets.page"));
const AdminExchangeTickets = lazy(() =>
  import("./pages/admin/ExchangeTickets.page")
);
const AdminCasinoSlot = lazy(() => import("./pages/admin/CasinoSlot.page"));
const AdminRiskMgt = lazy(() => import("./pages/admin/RiskMgt.page"));
const AdminDeposits = lazy(() => import("./pages/admin/Deposits.page"));
const AdminWithdrawals = lazy(() => import("./pages/admin/Withdrawals.page"));
const AdminPaymentMethods = lazy(() =>
  import("./pages/admin/PaymentMethods.page")
);
const AdminEarnings = lazy(() => import("./pages/admin/Earnings.page"));
const AdminEditUser = lazy(() => import("./pages/admin/EditUser.page"));
const AdminPages = lazy(() => import("./pages/admin/Pages.page"));
const EditPage = lazy(() => import("./pages/admin/EditPage.page"));
const AdminSupportChat = lazy(() => import("./pages/admin/SupportChat.page"));
const AdminBannersGallery = lazy(() =>
  import("./pages/admin/BannersGallery.page")
);
const AdminSettings = lazy(() => import("./pages/admin/Settings.page"));
const EmailTemplates = lazy(() => import("./pages/admin/EmailTemplates.page"));
const ViewScore = lazy(() => import("./pages/admin/ViewScore.page"));
//admin agents
const AdminPartnerHistory = lazy(() =>
  import("./pages/admin/AdminPartnerHistory.page")
);
const AdminPartnerPayments = lazy(() =>
  import("./pages/admin/AdminPartnerPayments.page")
);
const AdminUserCreditHistory = lazy(() =>
  import("./pages/admin/AdminUserCreditHistory.page")
);

const ExResults = lazy(() => import("./pages/admin/ExResults.page"));

///////////////////////////////////////////////////////////////////////

//for users
const Register = lazy(() => import("./pages/register/Register.page"));
const Login = lazy(() => import("./pages/login/Login.page"));
const DenyAccess = lazy(() => import("./pages/login/DenyAccess.page"));

const ResetPassword = lazy(() => import("./pages/login/ResetPassword.page"));
const HomePage = lazy(() => import("./pages/login/Login.page")); //for partners

////////////////////////
const PartnerPage = lazy(() => import("./pages/partner/Partner.page"));
const ViewPage = lazy(() => import("./pages/page/ViewPage.page.jsx"));
// const MyAdmin = lazy(() => import("./pages/myAdmin/MyAdmin"));
// const SettleQuizPage = lazy(() => import("./pages/SettleQuizPage/SettleQuizPage"));

//function
function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user); // Use the selectUser selector
  const token = useSelector((state) => state.user.token); // Use the selectToken selector

  useEffect(() => {
    dispatch(handleUpdateSlider(token));
    //update user details
    if (token) {
      dispatch(updateUserDetails({ user_id: user.id }, token));
    }
  }, []);

  //fetch rmgt
  const handleRmgt = async () => {
    try {
      const data = await fetchRmgt("mgt", token);
      //////////////console.log(data);
      if (data) {
        dispatch(setRmgt(data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  //onload
  useEffect(() => {
    handleRmgt();
    //////////////console.log('is in ts', rmgt);
  }, []);

  //30 days aff
  useEffect(() => {
    // Parse the URL to get the affiliate ID
    const urlParams = new URLSearchParams(window.location.search);
    const affId = urlParams.get("aff");

    if (affId) {
      // Save to cookie for 30 days
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30);
      document.cookie = `affiliateId=${affId}; expires=${expiryDate.toUTCString()}; path=/`;
    }
  }, []);

  //
  window.parent.postMessage({ domainBReady: true }, "http://localhost:3012");

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* for admins */}
        <Route
          path="/caff/admin/dashboard"
          element={
            user && user.role !== "player" ? (
              <AdminDashboard />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/users"
          element={
            user && user.role !== "player" ? (
              <AdminUsers />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/users/:userId"
          element={
            user && user.role !== "player" ? (
              <AdminEditUser />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/events"
          element={
            user && user.role !== "player" ? (
              <AdminEvents />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/exchange-events"
          element={
            user && user.role !== "player" ? (
              <AdminExchangeEvents />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/events/:eventId"
          element={
            user && user.role !== "player" ? (
              <AdminEventDetails />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/exchange-events/:eventId"
          element={
            user && user.role !== "player" ? (
              <AdminExchangeEventDetails />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/tickets"
          element={
            user && user.role !== "player" ? (
              <AdminTickets />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/exchange-tickets"
          element={
            user && user.role !== "player" ? (
              <AdminExchangeTickets />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/casino-slot-games"
          element={
            user && user.role !== "player" ? (
              <AdminCasinoSlot />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/risk-management"
          element={
            user && user.role !== "player" ? (
              <AdminRiskMgt />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/deposits"
          element={
            user && user.role !== "player" ? (
              <AdminDeposits />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/withdrawals"
          element={
            user && user.role !== "player" ? (
              <AdminWithdrawals />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/payment-methods"
          element={
            user && user.role !== "player" ? (
              <AdminPaymentMethods />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/earnings"
          element={
            user && user.role !== "player" ? (
              <AdminEarnings />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/pages"
          element={
            user && user.role === "admin" ? (
              <AdminPages />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/page/edit/:id"
          element={
            user && user.role === "admin" ? (
              <EditPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/support/chat"
          element={
            user && user.role !== "player" ? (
              <AdminSupportChat />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/banners-gallery"
          element={
            user && user.role !== "player" ? (
              <AdminBannersGallery />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/settings"
          element={
            user && user.role !== "player" ? (
              <AdminSettings />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/caff/admin/email-templates"
          element={
            user && user.role !== "player" ? (
              <EmailTemplates />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/caff/admin/user-credit-history/:id"
          element={
            user && user.role !== "player" ? (
              <AdminUserCreditHistory />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route path="/caff/admin/view-score/:id" element={<ViewScore />} />

        {/* admin partners */}
        <Route
          path="/caff/admin/history"
          element={
            user && user.role !== "player" ? (
              <AdminPartnerHistory />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/caff/admin/payments"
          element={
            user && user.role !== "player" ? (
              <AdminPartnerPayments />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/caff/admin/ex/results/:id"
          element={
            user && user.role !== "player" ? (
              <ExResults />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/*for users*/}
        <Route
          path="/register"
          element={user ? <Navigate to="/" replace /> : <Register />}
        />
        <Route
          path="/login"
          element={
            user && user.role === "player" ? (
              <Navigate to="/" replace />
            ) : user ? (
              <Navigate to="/caff/admin/dashboard" replace />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/reset-password"
          element={user ? <Navigate to="/" replace /> : <ResetPassword />}
        />

        <Route path="/deny-access" element={<DenyAccess />} />

        <Route
          path="/"
          element={
            user && user.role === "player" ? (
              <Navigate to="/deny-access" replace />
            ) : user ? (
              <Navigate to="/caff/admin/dashboard" replace />
            ) : (
              <HomePage />
            )
          }
        />

        <Route path="/partner" element={<PartnerPage />} />

        <Route path="/view-page/:id" element={<ViewPage />} />

        {/* <Route path="/my-admin" element={user ? <MyAdmin /> : <Navigate to="/login" replace />} />
            <Route path="/settle-quiz/:id" element={user ? <SettleQuizPage /> : <Navigate to="/login" replace />} /> */}
      </Routes>
    </Suspense>
  );
}

export default App;
