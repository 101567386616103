import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rmgt: []
};

const rmgtSlice = createSlice({
  name: 'rmgt',
  initialState,
  reducers: {
    setRmgt: (state, action) => {
      state.rmgt = action.payload;
    }
  }
});

export const { setRmgt } = rmgtSlice.actions;

export default rmgtSlice.reducer;